import { useCallback, useEffect, useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { newsAPIs } from '../../services';
import { createErrorNotification } from '../../utils/notifications';
import Button from '../../components/button';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import styles from './news.module.sass';
import { useDialog } from '../../providers/dialog.provider';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import { AddNews } from './modals/AddNews';

export const NewsDetailed = () => {
  const dialog = useDialog();
  const [article, setArticle] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const { id } = useParams();

  const history = useHistory();

  useEffect(() => {
    const fetchArticle = async () => {
      await newsAPIs
        .getNew(id)
        .then((res) => setArticle(res.new))
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при получении новости');
        });
    };

    if (id) {
      fetchArticle();
    }
  }, [id, refetch]);

  const handleRefetch = useCallback(() => {
    setRefetch((prev) => !prev);
  }, []);

  const handleDeleteArticle = useCallback(
    async (id) => {
      await newsAPIs
        .deleteNew(id)
        .then(() => {
          history.push('/handbook/news');
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при удалении новости');
        });
    },
    [history]
  );

  const askConfirmationDelete = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal value={entity} title={`Вы уверены, что хотите удалить новость?`} onAgree={handleDeleteArticle} />
      );
    },
    [dialog, handleDeleteArticle]
  );

  const showEditModal = useCallback(() => {
    dialog.open(<AddNews article={article} onActionSuccess={handleRefetch} />);
  }, [dialog, handleRefetch, article]);

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-min-h-full tw-w-full">
        <h2 className="tw-text-[#191919] tw-font-semibold tw-leading-[38px] tw-text-[32px] md:tw-mb-8 tw-mb-5 sm:tw-mb-7">
          Справочник новостей
        </h2>
        <div className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-min-h-full tw-w-full tw-p-6 tw-gap-5">
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-text-sm tw-leading-[18px]">
            <p className="tw-text-[#666666] ">Дата</p>
            <p className="tw-text-[#191919] tw-font-medium">{article?.date}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-text-sm tw-leading-[18px]">
            <p className="tw-text-[#666666] ">Текст</p>
            <p
              className={`tw-text-[#191919] ${styles.newsText}`}
              dangerouslySetInnerHTML={{ __html: article?.description }}
            />
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-6 tw-mt-3">
            <Button
              variant="text"
              onClick={() => showEditModal()}
              className="tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-items-center tw-text-sm tw-leading-[18px] tw-font-semibold tw-p-0 hover:tw-bg-transparent"
            >
              <EditIcon />
              Редактировать новость
            </Button>
            <Button
              variant="text"
              className="tw-w-fit tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold tw-p-0 hover:tw-bg-transparent"
              onClick={() => askConfirmationDelete(article)}
            >
              Удалить новость
            </Button>
          </div>
        </div>
      </div>
    </GeneralLayout>
  );
};
