import { Checkbox, Dialog, DialogActions } from '@mui/material';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { Form } from 'react-final-form';
import { useDialog } from '../../../providers/dialog.provider';
import { useCallback, useState, useEffect } from 'react';
import { createErrorNotification } from '../../../utils/notifications';
import { useSelector } from 'react-redux';
import { ReactComponent as AttachmentIcon } from '../../../icons/attachmentIcon.svg';
import { ReactComponent as CloseIcon } from '../../../icons/closeIcon.svg';

export const ProjectsFormModal = ({ title, onSubmit, onReject, value }) => {
  const dialog = useDialog();
  const [formValue, setFormValue] = useState(value);
  const [error, setError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [requireNameChanger, setRequireNameChanger] = useState(false);

  const organizationId = useSelector((store) => store.organizations.selected.id);

  const handleAddFile = (file) => {
    if (!file?.name?.endsWith('.xlsx')) {
      createErrorNotification('Файл должен быть формата .xlsx');
      return;
    }
    setSelectedFile(file);
  };

  useEffect(() => {
    if (JSON.stringify(formValue) !== JSON.stringify(value)) {
      setFormValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFormSubmit = useCallback(
    async (payload) => {
      const { id, name, address } = payload;
      const formBody = new FormData();
      const data = {
        name: name,
        organization_id: organizationId,
        address: address ?? '',
      };
      if (selectedFile !== null) {
        data.file = selectedFile;
        data.usegpt = requireNameChanger;
      }
      Object.entries(data).forEach(([key, value]) => {
        formBody.append(key, value);
      });
      setError(null);
      if (onSubmit) {
        try {
          if (!id) {
            await onSubmit(formBody);
          } else {
            await onSubmit(id, payload);
          }
        } catch (err) {
          console.error(err);
          if (err.statusCode === 409) {
            setError(true);
          } else {
            createErrorNotification('Ошибка при ' + (id ? 'редактировании' : 'создании') + ' проекта');
          }
        }
      }
    },
    [onSubmit, organizationId, requireNameChanger, selectedFile]
  );

  const handleFormReject = useCallback(() => {
    onReject && onReject();
    dialog.close();
  }, [onReject, dialog]);

  const validate = (values) => {
    if (values?.name?.length === 0) return;
    const errors = {};
    if (values?.name?.length < 5) {
      errors.name = 'Название должно содержать не менее 5 символов';
    }
    return errors;
  };
  useEffect(() => {
    return () => {
      setError(null)
    }
  })
  return (
    <Dialog open={dialog.visibility}>
      {value?.id ? (
        <Form
          validate={validate}
          validateOnBlur
          initialValues={formValue}
          onSubmit={handleFormSubmit}
          render={({ handleSubmit, values, submitError, submitting, form, invalid, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-p-6 tw-rounded-xl">
                <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
                <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-mb-14 md:tw-w-[440px]">
                  <div className="tw-flex tw-flex-col tw-gap-1">
                    <Input
                      form={form}
                      placeholder="Название проекта"
                      value={values['name']}
                      name="name"
                      type="text"
                      required
                    />
                    {error && (
                      <p className="tw-text-[#FF4343] tw-leading-4 tw-text-xs tw-font-medium tw-pl-4">
                        Такое название проекта уже используется
                      </p>
                    )}
                  </div>
                </div>

                <DialogActions className="tw-gap-[18px]">
                  <Button
                    className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent hover:tw-text-[#7F7F7F]"
                    variant="text"
                    disabled={submitting}
                    onClick={handleFormReject}
                  >
                    Отменить
                  </Button>
                  <Button
                    className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                    disabled={submitting && invalid && pristine}
                    type="submit"
                  >
                    Добавить
                  </Button>
                </DialogActions>
                {submitError && createErrorNotification(submitError)}
              </div>
            </form>
          )}
        ></Form>
      ) : (
        <Form
          validate={validate}
          initialValues={formValue}
          validateOnBlur
          onSubmit={handleFormSubmit}
          render={({ handleSubmit, values, submitError, submitting, form, invalid, pristine }) => (
            <form onSubmit={handleSubmit}>
              <div className="tw-p-6 tw-rounded-xl">
                <p className="tw-text-base tw-font-semibold tw-text-[#191919]">{title}</p>
                <div className="tw-flex tw-flex-col tw-gap-6 tw-mt-6 tw-mb-14 md:tw-w-[440px]">
                  <div className="tw-flex tw-flex-col tw-gap-4">
                    <div className="tw-flex tw-flex-col tw-gap-1">
                      <Input
                        form={form}
                        placeholder="Название проекта*"
                        value={values['name']}
                        name="name"
                        type="text"
                        required
                      />
                      {error && (
                        <p className="tw-text-[#FF4343] tw-leading-4 tw-text-xs tw-font-medium tw-pl-4">
                          Такое название проекта уже используется
                        </p>
                      )}
                    </div>
                    <Input
                      form={form}
                      placeholder="Адрес доставки"
                      value={values['address']}
                      name="address"
                      type="text"
                    />
                    {!selectedFile ? (
                      <>
                        <input
                          type="file"
                          name="files"
                          id="uploadBtn"
                          hidden
                          accept=".xlsx"
                          onChange={(e) => {
                            handleAddFile(e.target.files[0]);
                          }}
                        />
                        <label for="uploadBtn" className="tw-flex tw-flex-row tw-items-center tw-cursor-pointer">
                          <AttachmentIcon className="tw-mr-1" /> Загрузить Excel файл
                        </label>
                      </>
                    ) : (
                      <>
                        <div className="tw-flex tw-flex-row tw-gap-2 tw-items-center">
                          <p className="tw-text-sm tw-text-[#191919]">{selectedFile.name}</p>{' '}
                          <CloseIcon
                            className="tw-w-4 tw-h-4 tw-cursor-pointer"
                            onClick={() => setSelectedFile(null)}
                          />
                        </div>
                      </>
                    )}
                    <div className="tw-flex tw-flex-row tw-items-center tw-gap-1">
                      <Checkbox
                        className="tw-p-0"
                        value={requireNameChanger}
                        onChange={() => setRequireNameChanger((prev) => !prev)}
                      />
                      <p className="tw-text-sm">Использовать оптимизатор названий</p>
                    </div>
                  </div>
                </div>

                <DialogActions className="tw-gap-[18px]">
                  <Button
                    className="tw-w-[70px] tw-px-0 tw-text-[#666] tw-text-sm hover:tw-bg-transparent hover:tw-text-[#7F7F7F]"
                    variant="text"
                    disabled={submitting}
                    onClick={handleFormReject}
                  >
                    Отменить
                  </Button>
                  <Button
                    className="tw-w-[91px] tw-rounded-lg tw-font-semibold tw-text-sm"
                    disabled={submitting && invalid && pristine}
                    type="submit"
                  >
                    Добавить
                  </Button>
                </DialogActions>
                {submitError && createErrorNotification(submitError)}
              </div>
            </form>
          )}
        ></Form>
      )}
    </Dialog>
  );
};
