import { request } from '../utils/request';

export const userAPIs = {
  getLimits: () =>
    request('/api/users/limit', {
      method: 'GET',
    }),
  getUsersRequests: (query = '') =>
    request(`/api/admin/users/report?query=${query}`, {
      method: 'GET',
    }),
  sendFeedback: (body) =>
    request(`/api/users/feedback_form`, {
      method: 'POST',
      body: body,
    }),
  getGuide: () =>
    request('/api/organizations/user_guide', {
      method: 'GET',
    }),
  setUserReadGuide: () =>
    request('/api/user_prompts', {
      method: 'GET',
    }),
  getUserReadGuideStatus: () =>
    request('/api/user_prompts/check', {
      method: 'GET',
    }),
  getFaq: () =>
    request('/api/admin/faq', {
      method: 'GET',
    }),
  resetPassword: (body) =>
    request(`/api/reset`, {
      method: 'POST',
      body,
    }),
  changePassword: (token, body) =>
    request(`/api/reset/reset_pass/${token}`, {
      method: 'POST',
      body,
    }),
  updateUser: (body) =>
    request('/api/users/current', {
      method: 'PATCH',
      body,
    }),
};
