import Button from '../../../components/button';
import { Form } from 'react-final-form';
import { ReactComponent as ListIcon } from '../../../icons/listIcon.svg';
import { useState } from 'react';
import { Menu, MenuItem, Divider, Autocomplete, TextField, Tooltip } from '@mui/material';
import Input from '../../../components/input';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowDown } from '../../../icons/arrowDownIcon.svg';
import classes from '../suppliers.module.sass';

export const SearchBar = ({ onSubmit, handleChangeCategory, categories }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [choosenRegion, setChoosenRegion] = useState('');
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleRegionChange = (value) => {
    setChoosenRegion(value);
  };
  return (
    <div className="tw-flex tw-flex-row tw-gap-4 tw-w-full">
      <Button className="tw-w-fit tw-rounded-lg" onClick={handleMenuClick} disabled={categories?.length === 0}>
        <ListIcon className="tw-mr-1" /> <span className="tw-text-sm tw-font-semibold">Категории</span>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        className="tw-max-h-[500px] tw-mt-2"
        sx={{
          '& .MuiList-root': {
            paddingTop: 0,
            maxWidth: 'fit-content !important',
          },
        }}
      >
        <div className="lg:tw-w-fit tw-relative">
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-4 tw-py-[10px] tw-sticky tw-top-0 tw-bg-white tw-z-20">
            <p className="tw-text-[#191919] tw-text-sm tw-font-semibold">Категории</p>
            <p className="tw-text-[#191919] tw-text-sm tw-font-semibold">Поставщиков</p>
          </div>
          {categories && (
            <>
              <MenuItem
                className="hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-text-[#191919]"
                onClick={() => {
                  handleChangeCategory('');
                  handleMenuClose();
                }}
              >
                <div className="tw-flex tw-flex-row tw-justify-start">
                  <p>Все категории</p>
                </div>
              </MenuItem>
              {Object.keys(categories).map((category, index) => {
                return (
                  <>
                    {categories[category] !== 0 && (
                      <MenuItem
                        key={index}
                        className="hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-text-[#191919]"
                        onClick={() => {
                          handleChangeCategory(category);
                          handleMenuClose();
                        }}
                      >
                        <Tooltip title={category} enterDelay={500} followCursor>
                          <div className="tw-flex tw-flex-row tw-justify-between tw-w-full tw-gap-4" name={category}>
                            <p className="tw-max-w-[70%] lg:tw-max-w-full tw-truncate">{category}</p>
                            <p>{categories[category]}</p>
                          </div>
                        </Tooltip>
                      </MenuItem>
                    )}
                  </>
                );
              })}
            </>
          )}
        </div>
      </Menu>
      <div className=" tw-w-full">
        <Form
          onSubmit={({ search }) => onSubmit(search, choosenRegion ? choosenRegion?.name : '')}
          render={({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="tw-flex tw-flex-row tw-bg-white tw-w-full tw-rounded-lg tw-items-center"
            >
              <Input
                placeholder="Введите название товара или поставщика"
                name="search"
                type="text"
                className={classes.searchInput}
              />
              <Divider orientation="vertical" variant="middle" sx={{ height: '30px !important' }} />
              <RegionPicker onRegionChange={handleRegionChange} />
              <Button
                className="tw-w-[fit-content] tw-rounded-lg tw-text-sm tw-py-[14px] tw-px-[24px] tw-tranis"
                type="submit"
              >
                Найти
              </Button>
            </form>
          )}
        />
      </div>
    </div>
  );
};

export const RegionPicker = ({ onRegionChange, choosenRegion }) => {
  const regions = useSelector((state) => state.auth.regions);
  const handleChange = (value) => {
    if (!value) onRegionChange('')
    onRegionChange(value)
  }
  return (
    <div className="tw-w-[40%] tw-h-full">
      <Autocomplete
        onChange={(event, value) => handleChange(value)}
        sx={{
          border: 'none',
          '& .MuiOutlinedInput-root': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: '16px !important',
            '&.Mui-focused': {
              fieldset: {
                borderColor: 'transparent',
                border: 'none',
              },
            },
            '&:hover': {
              fieldset: {
                borderColor: 'transparent',
              },
            },
          },
          fieldset: {
            borderColor: 'transparent',
          },
          '& .MuiButtonBase-root': {
            transitionProperty: 'transform !important',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1) !important',
            transitionDuration: '150ms !important',
          },
        }}
        options={regions}
        noOptionsText={'Не найдено региона'}
        getOptionLabel={(regions) => regions.name}
        popupIcon={<ArrowDown />}
        isOptionEqualToValue={(option, value) => option?.name === value?.name}
        //value={regions.find((region) => region.region_id === choosenRegion.region_id)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Регион"
            fullWidth
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        
      />
    </div>
  );
};
