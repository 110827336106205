import { Popover, Transition } from '@headlessui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { login, logout } from '../../../store/actions/auth';
import { toggleOrganizationModal, toggleOrganizationModalCloseBtn } from '../../../store/actions/organizations';
import Button from '../../button';
import { Divider, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { MobileDropdownModal } from './MobileDropdownModal';
import { organizationsAPIs } from '../../../services';
import { createErrorNotification } from '../../../utils/notifications';
import { checkIsAdmin } from '../../../utils/common';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ChangePasswordModal } from '../../../pages/reset-password/components/ChangePasswordModal';

function UserZone({ user, organization }) {
  const [limits, setLimits] = useState({
    reached: 0,
    maxLimit: 0,
  });
  const history = useHistory();
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 427px)');

  const adminUser = sessionStorage.getItem('a_user');
  const currentUser = sessionStorage.getItem('c_user');

  const canReturn = Boolean(adminUser && currentUser);

  const toggleOrganizationShow = () => {
    toggleOrganizationModal();
    toggleOrganizationModalCloseBtn(true);
  };

  const returnToStartAccount = useCallback(async () => {
    const email = Buffer.from(adminUser.split(' ')[0], 'base64').toString('utf-8');
    const password = Buffer.from(adminUser.split(' ')[1], 'base64').toString('utf-8');
    await login({ email, password })
      .then(() => {
        history.push('/personal-area/users');
        sessionStorage.removeItem('c_user');
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при попытке вернуться в аккаунт');
      });
  }, [adminUser, history]);

  const showModalDropdown = useCallback(() => {
    dialog.open(
      <MobileDropdownModal
        user={user}
        organization={organization}
        showChangeOrganization={toggleOrganizationShow}
        limits={limits}
      />
    );
  }, [dialog, organization, user, limits]);

  const isAdmin = checkIsAdmin(user);

  const fetchOrg = useCallback(async () => {
    const organization_id = localStorage.getItem('organizationId');

    try {
      if (isAdmin) {
        const res = await organizationsAPIs.getAdminOrganization(organization_id);
        return res;
      } else {
        const res = await organizationsAPIs.getUserOrganization(organization_id);
        return res;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [isAdmin]);

  useEffect(() => {
    const fetchInitialLimits = async () => {
      try {
        const res = await fetchOrg();
        setLimits({
          reached: res.count_scan_in_month,
          maxLimit: res.scan_limit_max,
        });
      } catch (err) {
        console.error(err);
      }
    };
    fetchInitialLimits();
  }, [fetchOrg]);

  const showResetPasswordModal = useCallback(() => {
    dialog.open(<ChangePasswordModal />)
  }, [dialog])

  return (
    <div className="tw-relative tw-inline-flex">
      {!mobile && (
        <Popover>
          {({ open }) => (
            <>
              <Popover.Button className="tw-inline-flex tw-justify-center tw-items-center tw-group tw-outline-0 tw-relative tw-z-10 focus-visible:tw-outline-none">
                <span className="tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#0844B4] tw-uppercase tw-text-white">
                  {(user?.full_name?.[0] || '') + (user?.full_name?.[1] || '')}
                </span>
                <div className="tw-flex tw-items-center tw-truncate">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className={open ? 'tw-rotate-180 tw-transition-transform' : 'tw-rotate-0 tw-transition-transform'}
                  >
                    <path
                      d="M11.9988 15.5754C11.8655 15.5754 11.7362 15.5504 11.6108 15.5004C11.4855 15.4504 11.3815 15.3837 11.2988 15.3004L6.69883 10.7004C6.51549 10.5171 6.42383 10.2837 6.42383 10.0004C6.42383 9.71706 6.51549 9.48372 6.69883 9.30039C6.88216 9.11706 7.11549 9.02539 7.39883 9.02539C7.68216 9.02539 7.91549 9.11706 8.09883 9.30039L11.9988 13.2004L15.8988 9.30039C16.0822 9.11706 16.3155 9.02539 16.5988 9.02539C16.8822 9.02539 17.1155 9.11706 17.2988 9.30039C17.4822 9.48373 17.5738 9.71706 17.5738 10.0004C17.5738 10.2837 17.4822 10.5171 17.2988 10.7004L12.6988 15.3004C12.5988 15.4004 12.4905 15.4714 12.3738 15.5134C12.2572 15.5554 12.1322 15.5761 11.9988 15.5754Z"
                      fill="#7F7F7F"
                    />
                  </svg>
                </div>
              </Popover.Button>
              <Transition
                enter="tw-transition tw-ease-out tw-duration-200 tw-transform"
                enterFrom="tw-opacity-0 tw--translate-y-2"
                enterTo="tw-opacity-100 tw-translate-y-0"
                leave="tw-transition tw-ease-out tw-duration-200"
                leaveFrom="tw-opacity-100"
                leaveTo="tw-opacity-0"
                className={' tw-z-[9] tw-relative'}
              >
                <Popover.Panel className={'tw-z-0'}>
                  <div className="tw-origin-top-right  tw-z-[9] tw-absolute tw-top-[-19px] tw-right-1 tw-min-w-[241px] tw-bg-white tw-py-1.5 tw-pt-[26px] tw-rounded tw-shadow-lg tw-overflow-hidden tw-mt-[-0.05rem]">
                    <div className="tw-py-[16px] tw-px-3">
                      <p className="tw-font-medium tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-mb-[6px]">
                        {organization?.name}
                      </p>
                      <p className="tw-text-xs tw-text-[#999999] tw-font-medium tw-leading-4">{user?.email}</p>
                    </div>
                    <Divider variant="middle" sx={{ backgroundColor: '#F7F7F7' }} />
                    <ul>
                      <li>
                        <Button
                          variant="text"
                          className="tw-w-fit tw-whitespace-nowrap tw-text-[#191919] tw-font-normal tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
                          onClick={() => showResetPasswordModal()}
                        >
                          Изменить пароль
                        </Button>
                      </li>
                      <li>
                        <Button
                          variant="text"
                          className="tw-w-fit tw-whitespace-nowrap tw-text-[#191919] tw-font-normal tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
                          onClick={() => {}}
                        >
                          Лимит запросов {limits.reached} из {limits.maxLimit}
                        </Button>
                      </li>
                      <li>
                        <Button
                          variant="text"
                          className="tw-w-fit tw-whitespace-nowrap tw-text-[#191919] tw-font-normal tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
                          onClick={() => toggleOrganizationShow()}
                        >
                          Изменить организацию
                        </Button>
                      </li>
                      {canReturn && (
                        <li>
                          <Button
                            variant="text"
                            className="tw-w-fit tw-whitespace-nowrap tw-text-[#191919] tw-font-normal tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
                            onClick={() => returnToStartAccount()}
                          >
                            Вернуться в мой аккаунт
                          </Button>
                        </li>
                      )}
                      <li>
                        <Button
                          variant="text"
                          className="tw-w-fit tw-whitespace-nowrap tw-text-[#191919] tw-font-normal tw-cursor-pointer tw-text-sm hover:tw-bg-transparent hover:tw-text-[#0844B4] tw-py-[10px] tw-px-[14px]"
                          onClick={logout}
                        >
                          Выход
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      )}
      {mobile && (
        <>
          <div
            className="tw-inline-flex tw-justify-center tw-items-center tw-group tw-outline-0"
            onClick={() => {
              showModalDropdown();
            }}
          >
            <span className="tw-flex tw-justify-center tw-items-center tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#0844B4] tw-uppercase tw-text-white">
              {(user?.full_name?.[0] || '') + (user?.full_name?.[1] || '')}
            </span>
            <div className="tw-flex tw-items-center tw-truncate">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M11.9988 15.5754C11.8655 15.5754 11.7362 15.5504 11.6108 15.5004C11.4855 15.4504 11.3815 15.3837 11.2988 15.3004L6.69883 10.7004C6.51549 10.5171 6.42383 10.2837 6.42383 10.0004C6.42383 9.71706 6.51549 9.48372 6.69883 9.30039C6.88216 9.11706 7.11549 9.02539 7.39883 9.02539C7.68216 9.02539 7.91549 9.11706 8.09883 9.30039L11.9988 13.2004L15.8988 9.30039C16.0822 9.11706 16.3155 9.02539 16.5988 9.02539C16.8822 9.02539 17.1155 9.11706 17.2988 9.30039C17.4822 9.48373 17.5738 9.71706 17.5738 10.0004C17.5738 10.2837 17.4822 10.5171 17.2988 10.7004L12.6988 15.3004C12.5988 15.4004 12.4905 15.4714 12.3738 15.5134C12.2572 15.5554 12.1322 15.5761 11.9988 15.5754Z"
                  fill="#7F7F7F"
                />
              </svg>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default UserZone;
