import { useCallback, useEffect, useRef, useState } from 'react';
import { useDialog } from '../../../providers/dialog.provider';
import { CircularProgress, Dialog, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { projectsAPIs } from '../../../services';
import { useInfiniteScroll } from '../../../hooks/useInfiniteScroll';
import Button from '../../../components/button';
import Input from '../../../components/input';
import { Form, useField } from 'react-final-form';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { dispatch } from '../../../store';
import * as constants from '../../../store/constants/organizations';
import { useRouteMatch } from 'react-router-dom';

export const CheckCorrectProductsNames = () => {
  const dialog = useDialog();
  const report = useSelector((state) => state?.organizations?.selectedReport);
  const mobile = useMediaQuery('(max-width: 500px)');
  const [isUploading, setIsUploading] = useState(false);
  const route = useRouteMatch('/projects/:id');

  useEffect(() => {
    if (!route?.params?.id) {
      dialog.close();
    }
  }, [route, dialog]);

  const [lastElementId, setLastElementId] = useState(null);

  const getOptimizedNames = useCallback(
    async (page, limit, _, signal) => {
      if (report?.id && report?.last_upload_batch_id) {
        return projectsAPIs.getOptimizedProductsNames(report?.id, report?.last_upload_batch_id, page, limit, signal);
      }
    },
    [report?.id, report?.last_upload_batch_id]
  );

  const { data, goToNextPage, hasMore, isLoading, allData, handleForceRefetch } = useInfiniteScroll(
    getOptimizedNames,
    'products',
    1,
    10,
    ''
  );

  const observer = useRef();

  const lastRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          goToNextPage();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoading, hasMore, goToNextPage]
  );

  useEffect(() => {
    setLastElementId(data[data.length - 1]?._id);
  }, [data]);

  useEffect(() => {
    if (!allData?.optimized_all) {
      const timer = setTimeout(() => {
        handleForceRefetch();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [allData?.optimized_all, handleForceRefetch]);

  const handleStartSearch = useCallback(async () => {
    setIsUploading(true);
    await projectsAPIs
      .beginFileUpload(report?.id, report?.last_upload_batch_id)
      .then((res) => {
        dispatch({ type: constants.ORGANIZATIONS__SET_SELECTED_REPORT, report: res.report });
        setIsUploading(false);
        createNotification('Поиск запущен');
        dialog.close();
      })
      .catch((err) => {
        setIsUploading(false);
        createErrorNotification('Произошла ошибка при попытке начать поиск');
        console.error(err);
      })
      .finally(() => {
        setIsUploading(false);
      });
  }, [report?.id, dialog, report?.last_upload_batch_id]);

  const handleCancelUpload = useCallback(async () => {
    setIsUploading(true);
    await projectsAPIs
      .cancelFileUpload(report?.id, report?.last_upload_batch_id)
      .then((res) => {
        dispatch({ type: constants.ORGANIZATIONS__SET_SELECTED_REPORT, report: res.report });
        setIsUploading(false);
        createNotification('Загрузка файла отменена');
        dialog.close();
      })
      .catch((err) => {
        createErrorNotification('Произошла ошибка при отмене загрузки');
        setIsUploading(false);
        console.error(err);
      });
  }, [dialog, report?.id, report?.last_upload_batch_id]);

  return (
    <Dialog
      open={dialog.visibility}
      onClick={() => dialog.close()}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '100%' : '818px',
          maxWidth: mobile ? '100%' : 'inherit',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div
        className="tw-flex tw-flex-col tw-bg-white tw-rounded-lg tw-w-full sm:tw-min-h-[400px] tw-h-full"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="tw-pt-6 tw-px-[26px] tw-pb-[27px]">
          <p className="tw-text-[#191919] tw-font-semibold tw-leading-5">
            Проверьте корректность оптимизированных названий товаров
          </p>
        </div>
        <Form onSubmit={() => {}}>
          {() => (
            <>
              {!mobile && (
                <div className="tw-flex tw-flex-row tw-items-center tw-px-6 tw-w-full tw-max-w-[65%] tw-justify-between tw-mr-auto">
                  <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Измененные названия товаров</p>
                  <p className="tw-text-[#666666] tw-text-sm tw-leading-[18px]">Поменять на</p>
                </div>
              )}
              <div className="tw-flex tw-flex-col tw-divide-y tw-max-h-[601px] tw-overflow-auto tw-pl-[26px] tw-pr-[24px]">
                {data?.length > 0 && allData?.optimized_all ? (
                  <>
                    {data.map((product, index) => (
                      <div ref={product._id === lastElementId ? lastRef : null}>
                        <ProductNameField item={product} index={index} key={product._id} />
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-h-[200px]">
                      <CircularProgress />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </Form>
        <div
          className={`tw-flex tw-flex-row tw-pt-[25px] tw-px-[26px] tw-pb-6 tw-w-full tw-justify-end tw-items-center tw-gap-6 tw-mt-auto ${
            mobile && 'tw-border-t tw-border-[#B2B2B233]'
          }`}
        >
          <Button
            variant="text"
            onClick={handleCancelUpload}
            disabled={!data || isUploading}
            className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold"
          >
            Отменить загрузку
          </Button>
          <Button
            className="tw-w-fit tw-py-3 tw-px-4 tw-rounded-lg tw-text-sm tw-leading-[18px] tw-font-semibold"
            disabled={!allData?.optimized_all || isUploading}
            onClick={handleStartSearch}
          >
            Применить
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const ProductNameField = ({ form, item, index }) => {
  const { input } = useField(`item-${item?._id}`);
  const [product, setProduct] = useState(item);
  const mobile = useMediaQuery('(max-width: 500px)');

  const handleUpdateProductName = useCallback(async () => {
    const nameToChange = input.value;
    await projectsAPIs
      .updateOptimizedProductName({
        filtered_name: nameToChange,
        number: 0,
        product_id: item?._id,
      })
      .then((res) => {
        setProduct((prev) => ({
          ...prev,
          filtered_name: res.filtered_name,
        }));
        input.onChange('');
      })
      .catch((err) => {
        createErrorNotification('Произошла ошибка при обновлении названия');
        console.error(err);
      });
  }, [input, item?._id]);

  return (
    <>
      <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center tw-gap-[22px] tw-justify-between tw-pt-[14px] tw-pb-4">
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 sm:tw-max-w-[50%] sm:tw-w-[50%] tw-w-full tw-break-words">
          <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px]">
            <span className="tw-text-[#666666] tw-mr-2">{index + 1}.</span>
            {product?.filtered_name}
          </p>
        </div>
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-[6px] sm:tw-w-[50%] tw-w-full">
          {mobile && <p className="tw-text-sm tw-text-[#666666] tw-leading-[18px]">Поменять на</p>}
          <div className="tw-flex tw-flex-col tw-items-start tw-gap-3 tw-w-full">
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-[9px] tw-w-full">
              <Input name={`item-${item?._id}`} {...form} placeholder={'Название товара'} />

              <Button
                variant="text"
                disabled={input.value === ''}
                className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-sm tw-leading-[18px] disabled:tw-text-[#83A1D9]"
                onClick={handleUpdateProductName}
              >
                Применить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
