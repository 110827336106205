import moment from 'moment';
import React, { useEffect, useState } from 'react';
import GeneralLayout from '../../components/general-layout';
import changelog from './changelog.json';
import { newsAPIs } from '../../services';
import { createErrorNotification } from '../../utils/notifications';
import { NewsCard } from './components/NewsCard';
import Button from '../../components/button';

function ChangelogPage() {
  const [news, setNews] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  useEffect(() => {
    const getNews = () => {
      newsAPIs
        .getNews(5, pageNum, '&sort_by=date&sort_order=desc')
        .then((res) => {
          setNews((prev) => [...prev, ...res.data]);
          if (res.pages === res.page_num) {
            setLoadMore(false);
          } else setLoadMore(true);
        })
        .catch((err) => {
          createErrorNotification('Произошла ошибка при получении новостей');
          console.error(err);
        });
    };
    getNews();
  }, [pageNum]);

  const handleLoadMore = () => {
    setPageNum((prev) => prev + 1);
  };

  return (
    <GeneralLayout>
      <div className="tw-flex tw-flex-col tw-mx-auto">
        <h2 className="tw-text-[#191919] tw-leading-[38px] tw-font-semibold tw-text-[32px] tw-mb-8">Новости</h2>
        {news.length > 0 ? (
          <>
            <div className="tw-flex tw-flex-col tw-gap-4">
              {news.map((article) => (
                <NewsCard log={article} />
              ))}
              {loadMore && (
                <Button
                  className="tw-w-fit tw-mt-[40px] sm:tw-mt-8 tw-rounded-lg tw-py-[14px] tw-px-[18px] tw-self-center"
                  onClick={handleLoadMore}
                >
                  Показать ещё
                </Button>
              )}
            </div>
          </>
        ) : (
          <>
            <p>Пока новостей нет</p>
          </>
        )}
      </div>
    </GeneralLayout>
  );
}

export default ChangelogPage;
