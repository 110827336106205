import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import Button from '../../components/button';
import GeneralLayout from '../../components/general-layout';
import ExcelIcon from '../../icons/excel.png';
// import Lock from '../../icons/lock';
import { ReactComponent as AddIcon } from '../../icons/addIcon.svg';
import { ReactComponent as MoreIcon } from '../../icons/MoreIcon.svg';
import { ReactComponent as DeleteIcon } from '../../icons/deleteIcon.svg';
import { ReactComponent as EditIcon } from '../../icons/editIcon.svg';
import { getSampleReport } from '../../store/actions/organizations';
// import { checkIsAdmin } from '../../utils/common';
import classes from './projects.module.sass';
import { SearchInput } from '../../components/input/search';
import { useCrudEntity, CrudTable } from '../../components/table/CrudTable';
import { projectsAPIs } from '../../services';
import { useHistory, Link } from 'react-router-dom';
import DropdownMenu from '../../components/dropdown';
import { MenuItem } from '@mui/material';
import { useDialog } from '../../providers/dialog.provider';
import { ConfirmModal } from '../../components/modal/ConfirmModal';
import { ProjectsFormModal } from './components/ProjectsFormModal';
import { createNotification } from '../../utils/notifications';
import moment from 'moment';
import { usePagination } from '../../components/table/usePagination';
import { Pagination } from '../../components/table/pagination';
import { RequestLimitNotification } from '../../components/request-limit';
import { ReactComponent as LockIcon } from '../../icons/lockIcon.svg';
import { BlockedHostsModal } from '../../components/blocked-hosts/BlockedHostsModal';
import { getProjectStatus } from '../../utils/common';

function useOrganizationProject() {
  const organization_id =
    useSelector((store) => store?.organizations?.selected?.id) ?? localStorage.getItem('organizationId');
  return useCallback(
    (page_size, page_num, query) => {
      return projectsAPIs.getAllProjects(organization_id, page_size, page_num, query);
    },
    [organization_id]
  );
}

function ProjectsPage() {
  const history = useHistory();
  const organizationProjects = useOrganizationProject();
  const [data, setData] = useState([]);
  // const [showBlockedHostsModal, setShowBlockedHostsModal] = useState(false);
  const dialog = useDialog();

  const numberFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
    maximumFractionDigits: 0,
  });
  const columns = {
    name: {
      label: 'Название',
      renderBodyCell: (value) => {
        return (
          <Link className="tw-text-[#0844B4] hover:tw-text-[#0A50D4]" to={`/projects/${value.id}`}>
            {value.name}
          </Link>
        );
      },
    },
    created_at: {
      label: 'Дата создания',
      renderBodyCell: (value) => {
        return <>{moment(value.created_at).format('DD.MM.YYYY')}</>;
      },
    },
    price: {
      label: 'Цена',
      renderBodyCell: (value) => {
        return <>{numberFormatter.format(value.price)}</>;
      },
    },
    report_status: {
      renderHeaderCell: () => {
        return <div className="tw-flex tw-flex-row tw-justify-center">Статус</div>;
      },
      renderBodyCell: getProjectStatus,
    },
    product_count: {
      label: 'Количество позиций',
    },
  };

  const crudButtons = (entity) => {
    return (
      <>
        <DropdownMenu
          buttonChildren={
            <>
              <MoreIcon />
            </>
          }
          btnProps={{
            className: 'tw-p-0 tw-w-[fit-content] hover:tw-bg-transparent',
            variant: 'text',
          }}
        >
          <MenuItem
            className="hover:tw-bg-transparent hover:tw-text-[#0844B4]"
            onClick={() => showEditProjectForm(entity)}
            disableRipple
          >
            <EditIcon className="tw-mr-1" /> Редактировать
          </MenuItem>
          <MenuItem
            className="hover:tw-bg-transparent hover:tw-text-[#0844B4]"
            onClick={() => askConfirmationDelete(entity)}
            disableRipple
          >
            <DeleteIcon className="tw-mr-1" /> Удалить
          </MenuItem>
        </DropdownMenu>
      </>
    );
  };

  const projectsCrudAdapter = useCrudEntity({
    readAll: organizationProjects,
    read: (id) => {},
    create: (payload) => projectsAPIs.createProject(payload).then((res) => history.push(`/projects/${res.project.id}`)),
    edit: (id, payload) => projectsAPIs.updateProject(id, payload),
    delete: (id) =>
      projectsAPIs
        .deleteProject(id)
        .then(() => projectsCrudAdapter.readAll(10, 1, '').then((res) => setData(res.data))),
  });
  const {
    data: paginatedData,
    currentPage,
    handleChangeQuery,
    goToPage,
    total,
    isLoading,
    setPageSize,
    pageSize,
  } = usePagination(projectsCrudAdapter.readAll);

  useEffect(() => {
    setData(paginatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedData]);

  const createProject = useCallback(
    async (project) => {
      await projectsCrudAdapter.create(project).then(() => {
        createNotification('Проект создан', { type: 'success', hideProgressBar: true });
        dialog.close();
      });
    },
    [dialog, projectsCrudAdapter]
  );
  const editProject = useCallback(
    async (id, project) => {
      await projectsCrudAdapter.edit(id, project).then(() =>
        projectsCrudAdapter.readAll(10, 1, '').then((res) => {
          setData(res.data);
          dialog.close();
          createNotification('Проект изменён', { type: 'success', hideProgressBar: true });
        })
      );
    },
    [projectsCrudAdapter, dialog]
  );
  const askConfirmationDelete = useCallback(
    (project) => {
      dialog.open(
        <ConfirmModal
          value={project}
          title={`Вы уверены, что хотите удалить проект ${project.name}`}
          onAgree={projectsCrudAdapter.delete}
        />
      );
    },
    [dialog, projectsCrudAdapter]
  );

  const showCreateProjectForm = useCallback(() => {
    dialog.open(
      <ProjectsFormModal
        title="Создание проекта"
        value={{
          name: '',
        }}
        onSubmit={createProject}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog, projectsCrudAdapter]);

  const showEditProjectForm = useCallback(
    (project) => {
      dialog.open(<ProjectsFormModal value={project} title="Редактирование проекта" onSubmit={editProject} />);
    },

    [dialog, editProject]
  );

  const showBlockedHostsList = useCallback(() => {
    dialog.open(<BlockedHostsModal />);
  }, [dialog]);
  return (
    <GeneralLayout bg="white">
      <h1 className="tw-text-[2rem] tw-font-semibold tw-leading-[2.375rem] tw-text-[#191919] tw-mb-8">Проекты</h1>
      <div className="tw-mb-5">
        <RequestLimitNotification />
      </div>
      <div className="tw-bg-white tw-py-[25px] tw-px-[24px] tw-rounded-lg">
        <header className="tw-flex tw-items-center tw-justify-between tw-mb-5">
          <div className="tw-flex tw-flex-row tw-gap-6">
            <Form onSubmit={() => {}}>
              {() => (
                <form>
                  <SearchInput
                    name="search"
                    placeholder="Найти проект"
                    type="text"
                    onSearchChange={handleChangeQuery}
                  />
                </form>
              )}
            </Form>
            <button className={classes.button} onClick={() => getSampleReport()}>
              <div className={classes.excel} style={{ backgroundImage: `url(${ExcelIcon})` }} />
              <div className={classes.label}>Пример excel файла</div>
            </button>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
            <Button
              className="tw-w-fit tw-p-2 tw-flex tw-flex-row tw-items-center tw-gap-[2px]"
              variant="text"
              onClick={() => showBlockedHostsList()}
            >
              <LockIcon />
              <p className="tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold">
                Заблокированные источники
              </p>
            </Button>

            <Button variant="text" className="tw-p-2 tw-w-[fit-content]" onClick={() => showCreateProjectForm()}>
              <AddIcon className="tw-mr-[4px]" />
              Новый проект
            </Button>
          </div>
        </header>
        {!isLoading ? (
          <CrudTable rows={data} entityColumns={columns} crudButtons={crudButtons} />
        ) : (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-10">
            <div
              class="tw-inline-block tw-h-8 tw-w-8 tw-animate-spin tw-rounded-full tw-border-4 tw-border-solid tw-border-[#0844B4] tw-border-r-transparent tw-align-[-0.125em] tw-text-primary tw-motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            />
          </div>
        )}
      </div>
      <Pagination
        rowsPerPage={pageSize}
        count={total}
        page={currentPage}
        onPageChange={goToPage}
        onLimitChange={setPageSize}
      />
    </GeneralLayout>
  );
}

export default ProjectsPage;
