import Button from '../button';
import classes from './status-report.module.sass';
import { Divider, LinearProgress } from '@mui/material';
import { ReactComponent as RestartIcon } from '../../icons/restartIcon.svg';
import { ReactComponent as PauseIcon } from '../../icons/pauseIcon.svg';
import { projectsAPIs } from '../../services';
import { createErrorNotification, createNotification } from '../../utils/notifications';
import { getProjectStatus } from '../../utils/common';

export default function StatusReport({ isLoading, report, onRestart, onStop }) {
  if (!report) {
    return null;
  }
  const progress = Math.round(report.total_count ? (report.success_product_count / report.total_count) * 100 : 0);

  
  const handleStopSearch = async () => {
    await projectsAPIs
      .stopSearch(report?.project_id)
      .then(() => {
        createNotification('Поиск остановлен');
        onRestart();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка');
      });
  };
  const handleRestartSearch = async () => {
    await projectsAPIs
      .restartSearch(report?.project_id)
      .then(() => {
        createNotification('Поиск перезапущен');
        onRestart();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при попытке перезапуска');
      });
  };
  return (
    <div className={`${classes.root} tw-mb-4 sm:tw-mb-[26px] md:tw-mb-6 tw-w-full `}>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-rounded-lg tw-py-3 tw-px-[14px]">
        <p className="tw-text-sm tw-font-semibold tw-text-[#191919]">Cтатус отчета</p>
        <div className="tw-flex tw-flex-row tw-gap-4 tw-h-fit">
          <div className="tw-flex tw-flex-col tw-gap-[6px] tw-items-center">
            <p className=" tw-text-[#4C4C4C] tw-text-sm tw-leading-[18px]">
              Найдено товаров <span className="tw-text-[#191919] tw-font-medium">{report?.success_product_count}</span>{' '}
              из <span className="tw-text-[#191919] tw-font-medium">{report?.total_count}</span>
            </p>
            <LinearProgress
              value={progress}
              variant="determinate"
              className={`tw-w-full tw-bg-[#EEEEEE] ${classes.progressBar}`}
              sx={{
                color: '#000',
                borderRadius: '50px',
                span: {
                  backgroundColor: progress >= 100 ? '#49CA4E' : '#FEB945',
                },
              }}
            />
          </div>
          <Divider orientation="vertical" className="lg:tw-h-8" />
          {getProjectStatus({
            report_status: report?.status
          })}

          <Divider orientation="vertical" className="lg:tw-h-8" />
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-mr-3">
            <p className="tw-text-sm tw-text-[#666666] tw-font-medium tw-leading-[18px]">Количество товаров</p>
            <span className="tw-text-sm tw-font-semibold tw-text-[#0C0B31] tw-leading-no">
              {report.total_count ?? 0}{' '}
            </span>
          </div>
          <Divider orientation="vertical" className="lg:tw-h-8" />
          <div className="tw-flex tw-flex-row tw-items-center">
            {report?.status === 'finished' || report?.status === 'stopped' ? (
              <Button
                className="tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-text-sm tw-p-0 tw-h-fit hover:tw-bg-transparent"
                variant="text"
                onClick={() => handleRestartSearch()}
              >
                <RestartIcon /> Перезапустить поиск
              </Button>
            ) : (
              <Button
                className="tw-w-fit tw-flex tw-flex-row tw-items-center tw-gap-[2px] tw-text-sm tw-p-0 tw-h-fit hover:tw-bg-transparent"
                variant="text"
                onClick={() => handleStopSearch()}
              >
                <PauseIcon /> Остановить поиск
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
