import { Dialog, DialogActions, FormControlLabel, FormGroup, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../../providers/dialog.provider';
import {  Form,  useForm } from 'react-final-form';
import { useCallback, useEffect, useState } from 'react';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import { createErrorNotification } from '../../../../utils/notifications';
import { organizationsAPIs } from '../../../../services';
import { Checkbox } from '@mui/material';

export const AddOrganization = ({ onSubmit }) => {
  const mobile = useMediaQuery('(max-width: 500px)');
  const dialog = useDialog();
  const [selectedTypes, setSelectedTypes] = useState(new Set());

  const handleCheckboxChange = useCallback((event) => {
      const { value, checked } = event.target;
      const numberValue = parseInt(value, 10);

      setSelectedTypes((prevSelectedTypes) => {
        const value = new Set(prevSelectedTypes)
        if (checked) {
          value.add(numberValue)
        } else {
          value.delete(numberValue)
        }
        return value
      });
    },[]);


  const handleFormSubmit = useCallback(
    async (payload) => {

      try {
        const finalPayload = { ...payload, type: [...selectedTypes] };
        onSubmit && (await onSubmit(finalPayload));
        setSelectedTypes(new Set());
      } catch (err) {
        createErrorNotification(err);
      }
    },
    [onSubmit, selectedTypes]
  );

  const validate = (values) => {
    if (values?.inn?.length === 0 || values?.inn === '' || values?.inn === undefined) return;

    const errors = {};
    if (values?.inn?.length !== 10) {
      errors.inn = 'ИНН должно содержать 10 цифр';
    }
    return errors;
  };

  const handleDialogClose = () => {
    setSelectedTypes(new Set());
    dialog.close();
  };

  const checkIsDisabled = (form) => {
    const innField = form.getFieldState('inn')?.value;

    if (!innField || innField.length !== 10) return true;
    else return false;
  };

  return (
    <Dialog
      open={dialog.visibility}
      onClick={() => handleDialogClose()}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '90%' : '440px',
          margin: mobile ? '0px' : '32px',
        },
      }}
    >
      <Form
        onSubmit={handleFormSubmit}
        validate={validate}
        validateOnBlur
        render={({ handleSubmit, submitErrors, submitting, form, invalid, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="tw-flex tw-flex-col tw-bg-white tw-p-6 tw-w-full" onClick={(e) => e.stopPropagation()}>
              <p className="tw-text-[#191919] tw-leading-5 tw-font-semibold tw-mb-6">Новая организация</p>
              <div className="tw-mb-[56px] tw-w-full">
                <div className="tw-flex tw-flex-col tw-w-full tw-gap-6">
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">ИНН*</p>
                    <FormField form={form} />
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">
                      Название организации*
                    </p>
                    <Input form={form} name="name" type="text" required />
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Адрес</p>
                    <Input
                      form={form}
                      name="address"
                      type="text"
                      className={'tw-transition-colors'}
                      disabled={checkIsDisabled(form)}
                      sx={{
                        fieldset: {
                          backgroundColor: checkIsDisabled(form) ? '#EEE' : '',
                        },
                      }}
                    />
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Индекс</p>
                    <Input
                      form={form}
                      name="postal_code"
                      type="text"
                      disabled={checkIsDisabled(form)}
                      sx={{
                        fieldset: {
                          backgroundColor: checkIsDisabled(form) ? '#EEE' : '',
                        },
                      }}
                    />
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Район</p>
                    <Input
                      form={form}
                      name="municipality"
                      type="text"
                      disabled={checkIsDisabled(form)}
                      sx={{
                        fieldset: {
                          backgroundColor: checkIsDisabled(form) ? '#EEE' : '',
                        },
                      }}
                    />
                  </div>
                  <div className="tw-flex tw-flex-col tw-items-start tw-gap-[10px]">
                    <p className="tw-text-[#191919] tw-text-sm tw-leading-[18px] tw-font-medium">Признак</p>
                    <FormGroup column>
                      <FormControlLabel
                        control={<Checkbox value="1" onChange={handleCheckboxChange} />}
                        label="Государственный заказчик"
                      />
                      <FormControlLabel
                        control={<Checkbox value="2" onChange={handleCheckboxChange} />}
                        label="Муниципальный заказчик"
                      />
                      <FormControlLabel
                        control={<Checkbox value="3" onChange={handleCheckboxChange} />}
                        label="Автономное"
                      />
                      <FormControlLabel
                        control={<Checkbox value="4" onChange={handleCheckboxChange} />}
                        label="Бюджетное"
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
              <DialogActions className="tw-flex tw-flex-row tw-gap-6 tw-items-center tw-justify-end">
                <Button
                  variant="text"
                  className="tw-w-fit tw-p-0 tw-text-[#666666] tw-text-sm hover:tw-bg-transparent tw-leading-[18px] hover:tw-text-[#7f7f7f]"
                  disabled={submitting}
                  onClick={() => handleDialogClose()}
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-fit tw-px-4 tw-py-3 tw-text-white tw-text-sm tw-leading-[18px]"
                  disabled={invalid || pristine || submitting}
                  type="submit"
                >
                  Создать
                </Button>
              </DialogActions>
              {submitErrors && createErrorNotification('Что-то пошло не так')}
            </div>
          </form>
        )}
      />
    </Dialog>
  );
};

const FormField = ({ form }) => {
  const innValue = form.getFieldState('inn')?.value;
  const { change } = useForm();

  useEffect(() => {
    if (innValue === undefined || innValue === '') return;
    if (innValue.length < 10) return;
    const getOrganizationInfo = async () => {
      await organizationsAPIs
        .getInfoByInn(innValue)
        .then((res) => {
          change('address', res?.address ?? '');
          change('postal_code', res?.postal_code ?? '');
          change('municipality', res?.municipality ?? '');
        })
        .catch((err) => console.error(err));
    };
    getOrganizationInfo();
  }, [innValue, change]);

  return (
    <>
      <Input form={form} name="inn" type="number" required />
    </>
  );
};
