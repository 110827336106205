import cx from 'classnames';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import AuthLayout from '../../components/auth-layout';
import Button from '../../components/button';
import Input from '../../components/input';
import { login } from '../../store/actions/auth';
import classes from './login.module.sass';
import { useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

export default function LoginPage() {
  let errorMessage = useSelector((state) => state.auth.error);
  useEffect(() => {
    const clearStorages = () => {
      localStorage.clear()
      sessionStorage.clear()
    }
    clearStorages()
  }, [])
  return (
    <AuthLayout bg="white">
      <div className={`tw-flex tw-flex-col tw-items-start ${classes.wrapper}`}>
        <h1 className="tw-text-3xl tw-text-gray-800 tw-font-bold tw-mb-1">Добро пожаловать!</h1>
        <p className={classes.subtext}>Пожалуйста, авторизуйтесь.</p>
        <Form onSubmit={login}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className={cx('tw-w-full tw-flex tw-flex-col tw-h-full', classes.form)}>
              <div className={`tw-w-full ${errorMessage ? 'tw-mb-3' : 'tw-mb-8'}`}>
                <Input
                  form={form}
                  label="Email"
                  onChange={() => console.log('From props')}
                  name="email"
                  placeholder="Логин"
                  type="email"
                  required
                  className={classes.input}
                  size="small"
                />

                <Input
                  form={form}
                  label="Пароль"
                  name="password"
                  placeholder="Пароль"
                  type="password"
                  required
                  className={classes.input}
                  size="small"
                />
                <Link to='/reset-password' className='tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold tw-mt-0'>
                Забыли пароль?
                </Link>
                {errorMessage && <div className={classes.error}>Неверный логин или пароль.</div>}
              </div>
              <Button type="submit">Начать</Button>
            </form>
          )}
        </Form>
      </div>
    </AuthLayout>
  );
}
