import { Form } from 'react-final-form';
import Button from '../button';
import Input from '../input';
import classes from './add-product-panel.module.sass';
import { addProduct } from '../../store/actions/organizations';
import { useState } from 'react';
import CreateProjectCardInline from '../create-project-card-inline';
import { UNIT_OPTIONS } from '../../utils/constant';

export default function AddProductPanel({ reportId, showUploadFile, onReloadProducts }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [unit, setUnit] = useState(UNIT_OPTIONS[0]);

  const handleAddProduct = async ({ name, amount }, form) => {
    try {
      if (!reportId || isSubmitting || !name) {
        return;
      }
      const correctName = name.replace(/\s+/g, ' ').trim()
      setIsSubmitting(true);
      const res = await addProduct(reportId, correctName, parseInt(amount), unit?.value);
      if (res) {
        await onReloadProducts();
      }
    } finally {
      setIsSubmitting(false);
      form.restart();
    }
  };

  const validate = (values) => {
    const regex = /[^a-zA-Zа-яёА-ЯЁ0-9\s,.%:;()"'-]/g;
    const errors = {};
    const invalidChars = values?.name?.match(regex)
    if (values.name?.length > 600 ) {
      errors.name = 'Название должно содержать не более 600 символов'
    }
    if (regex.test(values.name)) {
      const uniqueInvalidCharts = [...new Set(invalidChars)]
      errors.name = `Название содержит недопустимые символы: ${uniqueInvalidCharts.join(',')}`;
    }
    return errors;
  };


  return (
    <>
      <Form
        validate={validate}
        onSubmit={({ name, amount, unit }, form) => handleAddProduct({ name, amount, unit }, form)}
      >
        {({ handleSubmit, pristine, invalid}) => (
          <form onSubmit={handleSubmit} className={`${classes.form} tw-flex tw-flex-col tw-items-start tw-gap-4 `}>
            <div className={`tw-flex tw-flex-row  tw-w-full tw-bg-white tw-rounded-lg ${invalid ? 'tw-mb-[11px]' : ''}`}>
              <Input
                className={`${classes.input} tw-w-full tw-rounded-lg`}
                name="name"
                placeholder="Введите название продукта как можно точнее"
                id="productNameInput"
                required
              />
              <div className={`tw-flex tw-flex-row ${classes.bordered_container} tw-relative`}>
                <Input
                  type="number"
                  min={0}
                  defaultValue={0}
                  className={classes.input}
                  name="amount"
                  placeholder="Количество"
                  id="productAmountInput"
                />
                <Button type="submit" className="tw-max-w-[fit-content] tw-rounded-lg" disabled={isSubmitting || invalid || pristine}>
                  Добавить
                </Button>
              </div>
            </div>
            <div className="tw-w-full">{showUploadFile && <CreateProjectCardInline />}</div>
          </form>
        )}
      </Form>
    </>
  );
}
