import { Dialog, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { Field, Form } from 'react-final-form';
import Input from '../../../components/input';
import ReactQuill from 'react-quill';
import Button from '../../../components/button';
import moment from 'moment';
import styles from './addNews.module.sass';
import { newsAPIs } from '../../../services';
import { createErrorNotification, createNotification } from '../../../utils/notifications';

export const AddNews = ({ article, onActionSuccess }) => {
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 500px)');

  const handleAddNew = async (value) => {
    const article = {
      date: moment().format('DD.MM.YYYY'),
      description: value?.text,
      title: value?.title,
    };

    await newsAPIs
      .addNew(article)
      .then(() => {
        createNotification('Новость создана');
        onActionSuccess && onActionSuccess();
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при добавлении новости');
      });
  };

  const handleEditNew = async (value) => {
    const articleToEdit = {
      date: article?.date,
      description: value?.text,
      title: value?.title,
    };
    await newsAPIs
      .editNew(article?.id, articleToEdit)
      .then(() => {
        createNotification('Новость изменена');
        onActionSuccess && onActionSuccess();
        dialog.close();
      })
      .catch((err) => {
        console.error(err);
        createErrorNotification('Произошла ошибка при изменении новости');
      });
  };

  const currentAction = article ? handleEditNew : handleAddNew;

  const validate = (values) => {
    const errors = {};
    if (values.title === '' || !values.title) {
      errors.title = 'Обязательно для заполнения';
    }
    if (values.text === '' || values.text === '<p><br></p>' || !values.text) {
      errors.text = 'Обязательно для заполнения';
    }

    return errors;
  };

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          width: mobile ? '100%' : '632px',
          margin: mobile ? '0px' : '32px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-pt-6 tw-px-8 tw-pb-8 tw-bg-white tw-rounded-xl">
        <h6 className="tw-text-[#191919] tw-text-lg tw-leading-5 tw-font-medium tw-mb-8">
          {!article ? 'Создание новости' : 'Редактирование новости'}
        </h6>
        <Form
          validate={validate}
          onSubmit={(values) => currentAction(values)}
          initialValues={{
            title: article && article?.title,
            text: article && article?.description,
          }}
        >
          {({ handleSubmit, invalid, pristine }) => (
            <div className="tw-flex tw-flex-col tw-gap-8">
              <Field
                name="title"
                render={({ input }) => (
                  <div className="tw-flex tw-flex-col tw-flex-start tw-w-full tw-gap-3">
                    <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px] tw-font-medium">Заголовок</p>
                    <Input {...input} required />
                  </div>
                )}
              />

              <div className="tw-flex tw-flex-col tw-flex-start tw-w-full tw-gap-3 tw-mb-2" id="text-body">
                <p className="tw-text-[#191919 tw-text-sm tw-leading-[18px] tw-font-medium">Текст</p>
                <Field
                  name="text"
                  render={({ input }) => (
                    <div className="tw-w-full tw-h-full">
                      <ReactQuill
                        {...input}
                        bounds={'#text-body'}
                        className={styles.editor}
                        modules={{
                          toolbar: [
                            ['bold', 'italic', 'underline'],
                            [{ 'color': [] }],
                            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                            
                            ['image', 'link'],
                           
                            ['clean'],
                          ],
                        }}
                      />
                    </div>
                  )}
                />
              </div>
              <div className="tw-flex tw-flex-row tw-w-full tw-gap-6 tw-justify-end tw-mt-auto">
                <Button
                  variant="text"
                  onClick={() => dialog.close()}
                  className=" tw-w-fit tw-text-[#666666] tw-text-sm tw-leading-[18px] tw-font-semibold tw-p-0 hover:tw-bg-transparent"
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-fit tw-px-4 tw-py-3 tw-rounded-lg tw-text-sm tw-leading-[18px] "
                  type="submit"
                  disabled={invalid || pristine}
                  onClick={handleSubmit}
                >
                  {!article ? 'Создать' : 'Сохранить'}
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </Dialog>
  );
};
