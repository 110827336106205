import { Dialog, useMediaQuery } from '@mui/material';
import { useDialog } from '../../../providers/dialog.provider';
import { Form } from 'react-final-form';
import Input from '../../../components/input';
import Button from '../../../components/button';
import { useSelector } from 'react-redux';
import { userAPIs } from '../../../services';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { createErrorNotification } from '../../../utils/notifications';

export const ChangePasswordModal = () => {
  const dialog = useDialog();
  const mobile = useMediaQuery('(max-width: 500px)');
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();

  const validate = (values) => {
    const errors = {};
    if (values?.password?.length < 8) {
      errors.password = 'Пароль должен содержать более 8 символов';
    }

    if (values?.repeatPassword !== values?.password) {
      errors.repeatPassword = 'Пароли не совпадают';
    }

    return errors;
  };

  const clearStorages = () => {
    localStorage.clear()
    sessionStorage.clear()
  }

  const handleResetPassword = async (values) => {
    const body = {
      email: user?.email,
      full_name: user?.full_name,
      password: values.password,
    };
    await userAPIs
      .updateUser(body)
      .then(() => {
        clearStorages()
        history.push('/success-reset');
      })
      .catch((err) => {
        createErrorNotification('Произошла ошибка при смене пароля');
        console.error(err);
      });
  };

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          minWidth: mobile ? '100%' : '418px',
          margin: mobile ? '0px' : '15px',
          height: mobile ? '100%' : 'fit-content',
          maxHeight: mobile ? '100%' : 'calc(100% - 64px)',
        },
      }}
    >
      <div className={`tw-flex tw-flex-col tw-rounded-xl ${mobile ? 'tw-p-4' : 'tw-p-6'}`}>
        <p className="tw-leading-5 tw-text-[#191919] tw-font-semibold tw-mb-4">Изменить пароль</p>
        <Form
          onSubmit={(values) => {
            handleResetPassword(values);
          }}
          validate={validate}
        >
          {({ handleSubmit, invalid, pristine }) => (
            <>
              <div
                className={`tw-flex tw-flex-col tw-items-start tw-w-full tw-mb-5 ${invalid ? 'tw-gap-5' : 'tw-gap-2'}`}
              >
                <Input name="password" required type="password" />
                <p className="tw-text-[#39393A] tw-text-sm tw-leading-[18px]">
                  Придумайте пароль, в котором содержится от 8 символов
                </p>
              </div>
              <Input name="repeatPassword" required type="password" />

              <div
                className={`tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-end tw-gap-6  ${
                  mobile ? 'tw-mt-10' : 'tw-mt-14'
                }`}
              >
                <Button
                  variant="text"
                  className="tw-w-fit tw-p-0 hover:tw-bg-transparent tw-text-sm tw-text-[#666666] tw-leading-[18px]"
                >
                  Отменить
                </Button>
                <Button
                  className="tw-w-fit tw-rounded-lg tw-py-3 tw-px-4 tw-text-sm tw-leading-[18px]"
                  disabled={pristine || invalid}
                  onClick={handleSubmit}
                >
                  Изменить
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
    </Dialog>
  );
};
