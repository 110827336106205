import { request } from '../utils/request';

export const newsAPIs = {
  getNews: (page_size, page_num = 1, query) =>
    request(`/api/admin/news?page_size=${page_size}&page_num=${page_num}&query=${query}`, {
      method: 'GET',
    }),
  getNew: (id) =>
    request(`/api/admin/news/${id}`, {
      method: 'GET',
    }),
  addNew: (body) =>
    request('/api/admin/news', {
      method: 'POST',
      body: body,
    }),
  deleteNew: (id) =>
    request(`/api/admin/news/${id}`, {
      method: 'DELETE',
    }),
  editNew: (id, body) =>
    request(`/api/admin/news/${id}`, {
      method: 'PATCH',
      body: body,
    }),
};
