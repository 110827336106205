import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import LogoIcon from '../../icons/logo';
import { ReactComponent as LogoMini } from '../../icons/LogoMini.svg';
import { ReactComponent as ArrowIcon } from '../../icons/arrowIcon.svg';
import { checkIsAdmin } from '../../utils/common';
import { ReactComponent as AnalyticIcon } from '../../icons/analyticsIcon.svg';
import { AdditionalIcon, AnalyticsIcon, DesktopIcon, ProjectIcon, RequestIcon, SettingIcon, TaskIcon } from './icons';
import PersonalIcon from './icons/PersonalIcon';
import { useMediaQuery } from '@mui/material';
import { ReactComponent as AdminIcon } from '../../icons/adminIcon.svg';
// import {ReactComponent as FAQIcon} from '../../icons/FAQ.svg'
import { FAQIcon } from './icons/FAQIcon';

function Sidebar() {
  const location = useLocation();
  const [open, setOpen] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [isExpanded, setIsExpanded] = useState(JSON.parse(localStorage.getItem('sidebar-expanded') || 'true'));
  const user = useSelector((state) => state.auth.user);
  const isAdmin = checkIsAdmin(user);

  const handleClick = (e, name) => {
    e.preventDefault();
    if (!isExpanded) setIsExpanded(true);
    setOpen({ ...open, [name]: !open[name] });
  };

  const Menu = [
    {
      url: '/',
      name: 'Рабочий стол',
      icon: <DesktopIcon isActive={location.pathname === '/'} />,
    },
    {
      url: '/projects',
      name: 'Проекты',
      icon: <ProjectIcon isActive={location.pathname.includes('/projects')} />,
    },
    // ...(user.email !== 'testpredictive@mail.ru'
    //   ?
    // ...(isAdmin
    //   ? [
    //       {
    //         url: '/statistics',
    //         name: 'Аналитика',
    //         icon: <AnalyticIcon className={`tw-fill-white tw-shrink-0 tw-h-6 tw-w-6`} />,
    //       },
    //     ]
    //   : []),

    {
      url: '/chatbot',
      name: 'Чат-бот',
      icon: <RequestIcon isActive={location.pathname.includes('/chatbot')} />,
    },
    ...(isAdmin
      ? [
          // {
          //   url: '/statistics/archived-projects',
          //   name: 'Отчеты',
          //   icon: <AnalyticsIcon isActive={location.pathname.includes('/statistics')} />,
          // },
          {
            url: '/suppliers',
            name: 'Поставщики',
            icon: <TaskIcon isActive={location.pathname.includes('/suppliers')} />,
          },
          {
            url: '/personal-area/users',
            name: 'Админ. панель',
            icon: <AdminIcon />,
            subItems: [
              { url: '/personal-area/users', name: 'Доступы' },
              { url: '/feedback', name: 'Обратная связь' },
              {
                url: '/handbook',
                name: 'Справочники',
                subItems: [
                  { url: '/handbook/categories', name: 'Категории' },
                  { url: '/handbook/sizes', name: 'Типовые размеры' },
                  { url: '/handbook/news', name: 'Новости'}
                ],
              },
              { url: '/support-requests', name: 'Запрос техподдержки' },
              { url: '/incorrect-prices', name: 'Ошибочная цена' },
              { url: '/requests', name: 'Запросы' },
              { url: '/2gis', name: 'Импорт 2gis' },
              { url: '/tests', name: 'Тестирование' },
              { url: '/settings', name: 'Настройки' },
            ],
          },
        ]
      : []),
    {
      url: '/technical-support',
      name: 'Тех. поддержка',
      icon: <SettingIcon isActive={location.pathname.includes('/technical-support')} />,
    },
    {
      url: '/changelog',
      name: 'Новости',
      icon: <AdditionalIcon isActive={location.pathname.includes('/changelog')} />,
    },
    {
      url: '/faq',
      name: 'FAQ',
      icon: <FAQIcon isActive={location.pathname.includes('/faq')} />,
    },
  ];

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (location.pathname.includes('/projects/')) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }
  }, [location]);

  const showSidebarIcon = useMediaQuery('(max-width: 1023px)');

  const SidebarItem = ({ url, name, icon, subItems, depth, depthStep = 12 }) => {
    if (subItems && Array.isArray(subItems)) {
      return (
        <>
          <li
            className={classNames(
              `tw-cursor-pointer tw-rounded-lg tw-mb-1 last:tw-mb-0 tw-transition-colors`,
              !subItems && 'hover:tw-bg-white/[0.07]',
              depth === 1 ? 'tw-p-3' : 'tw-p-[10px] tw-pl-[20px]'
            )}
          >
            <a
              href="/"
              className={classNames(
                'tw-block tw-text-white hover:tw-text-white tw-truncate tw-transition tw-duration-150'
              )}
              onClick={(e) => handleClick(e, name)}
            >
              <div className="tw-flex tw-items-center tw-justify-between">
                <div className="tw-flex tw-items-center">
                  {icon}
                  <span
                    className={classNames(
                      'lg:tw-opacity-0 lg:tw-sidebar-expanded:tw-opacity-100 2xl:tw-opacity-100 tw-duration-200 tw-text-sm',
                      depth === 1 ? 'tw-font-semibold' : 'tw-font-medium',
                      icon && 'tw-ml-3'
                    )}
                  >
                    {name}
                  </span>
                </div>
                <div className="tw-flex tw-shrink-0 tw-ml-2 lg:tw-opacity-0 lg:tw-sidebar-expanded:tw-opacity-100 2xl:tw-opacity-100 tw-duration-200">
                  <svg
                    className={classNames(
                      'tw-w-3 tw-h-3 tw-shrink-0 tw-ml-1 tw-mr-3 tw-fill-current tw-text-white tw-transition-all',
                      open[name] && 'tw-rotate-180 tw-will-change-transform'
                    )}
                    viewBox="0 0 12 12"
                  >
                    <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                  </svg>
                </div>
              </div>
            </a>
            <div className="lg:tw-hidden lg:tw-sidebar-expanded:tw-block 2xl:tw-block">
              <ul className={classNames('tw-mt-3', !open[name] && 'tw-hidden', depth > 1 && 'tw-ml-[10px] ')}>
                {subItems.map((subItem, _i) => (
                  <>
                    <SidebarItem
                      key={_i}
                      icon={subItem.icon}
                      name={subItem.name}
                      url={subItem.url}
                      subItems={subItem?.subItems}
                      depth={depth + 1}
                    />
                  </>
                ))}
              </ul>
            </div>
          </li>
        </>
      );
    } else
      return (
        <>
          <li
            className={classNames(
              'tw-cursor-pointer  tw-rounded-lg tw-mb-1 last:tw-mb-0 hover:tw-bg-white/[0.07]',
              location.pathname === url && 'tw-bg-white/[0.15]',
              depth === 1 ? 'tw-p-3' : 'tw-p-[10px] tw-pl-[20px]'
            )}
          >
            <NavLink className="tw-block tw-text-white tw-truncate tw-transition tw-duration-150" exact to={url}>
              <div className="tw-flex tw-items-center">
                {icon}
                <span
                  className={classNames(
                    'lg:tw-opacity-0 lg:tw-sidebar-expanded:tw-opacity-100 2xl:tw-opacity-100 tw-duration-200 tw-text-sm',
                    depth === 1 ? 'tw-font-semibold' : 'tw-font-medium',
                    icon && 'tw-ml-3'
                  )}
                >
                  {name}
                </span>
              </div>
            </NavLink>
          </li>
        </>
      );
  };

  return (
    <>
      {showSidebarIcon && (
        <div
          className="tw-fixed tw-top-[10px] sm:tw-top-6 tw-left-4 tw-rounded-full tw-p-3 tw-inline-flex tw-items-center tw-bg-[#fff] tw-z-30 tw-shadow-[0_2px_12px_0_rgba(178, 178, 178, 0.2)]"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2 19V17.0313H22V19H2ZM2 12.9844V11.0156H22V12.9844H2ZM2 6.96875V5H22V6.96875H2Z" fill="#7F7F7F" />
          </svg>
        </div>
      )}

      <div className={isExpanded ? 'tw-sidebar-expanded' : ''}>
        <div
          onClick={() => setIsOpen(false)}
          className={classNames(
            isOpen ? 'tw-opacity-100' : 'tw-opacity-0 tw-pointer-events-none',
            'tw-fixed tw-inset-0 tw-bg-[#213966] tw-bg-opacity-30 tw-z-40 lg:tw-hidden lg:tw-z-auto tw-transition-opacity tw-duration-200'
          )}
          aria-hidden="true"
        ></div>

        <div
          id="sidebar"
          className={classNames(
            isOpen ? 'tw-translate-x-0' : 'tw--translate-x-64',
            'tw-flex tw-flex-col tw-overflow-x-hidden tw-absolute tw-z-40 tw-left-0 tw-top-0 lg:tw-static lg:tw-left-auto lg:tw-top-auto lg:tw-translate-x-0 tw-transform tw-h-screen tw-overflow-y-scroll lg:tw-overflow-y-auto tw-no-scrollbar tw-w-64 lg:tw-w-20 lg:tw-sidebar-expanded:!tw-w-64 2xl:!w-64 tw-shrink-0 tw-bg-[#213966] tw-p-4 tw-transition-all tw-duration-200 tw-ease-in-out'
          )}
          style={{ backgroundImage: '#213966', backgroundSize: 'cover' }}
        >
          <div className="tw-flex tw-justify-between tw-mb-10 tw-pr-3 sm:tw-px-2">
            <button className="lg:tw-hidden tw-text-gray-500 hover:tw-text-gray-400" onClick={() => setIsOpen(!isOpen)}>
              <span className="tw-sr-only">Close sidebar</span>
              <svg className="tw-w-6 tw-h-6 tw-fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
              </svg>
            </button>
            <Navbar.Brand as={Link} to="/" className="tw-block">
              {isExpanded ? <LogoIcon primary={true} /> : <LogoMini />}
            </Navbar.Brand>
          </div>

          <div className="tw-space-y-8">
            <div>
              <h3
                className="tw-text-xs tw-uppercase tw-text-gray-500 tw-font-semibold tw-hidden "
                style={{ marginLeft: '8px' }}
              >
                <span
                  className="tw-hidden lg:tw-block lg:tw-sidebar-expanded:tw-hidden tw-text-center tw-w-6"
                  aria-hidden="true"
                >
                  •••
                </span>
                <span className="tw-hidden lg:tw-sidebar-expanded:tw-block" style={{ color: 'transparent' }}>
                  Предиктивная аналитика
                </span>
              </h3>
              <ul
                className="tw-mt-3"
                style={{
                  width: 'calc(100%)',
                  marginLeft: '',
                }}
              >
                {Menu.map((item, i) => (
                  <SidebarItem
                    key={i}
                    icon={item.icon}
                    url={item.url}
                    name={item.name}
                    subItems={item?.subItems}
                    depth={1}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-mt-auto">
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="tw-align-bottom tw-hidden lg:tw-inline-flex tw-justify-center "
            >
              <div className="tw-sidebar-expanded:tw-px-5 tw-sidebar-expanded:tw-py-2 tw-bg-white/[0.15] hover:tw-bg-white/[0.07] tw-flex tw-flex-row tw-items-center tw-sidebar-expanded:tw-w-[85px] tw-justify-center tw-w-[48px] tw-h-[43px] tw-sidebar-expanded:tw-h-auto tw-transition-[colors] tw-rounded-lg">
                <span className="tw-sr-only">Expand / collapse sidebar</span>
                <ArrowIcon className="tw-w-[19px] tw-h-[23px] tw-fill-current tw-sidebar-expanded:tw-rotate-180 tw-transition-all" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
