import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query';
import { logout } from '../store/actions/auth';

export const baseQueryLocal = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_SERVER_URL,
  mode: 'cors',
  prepareHeaders: (headers) => {
    const userToken = localStorage.getItem('token');
    if (userToken) {
      headers.set('Authorization', `Bearer ${userToken}`);
    }

    return headers;
  },
});

const checkAuthStatus = (res) => {
  if (!('error' in res)) return false;
  if (res?.error) {
    if ('statusCode' in res.error) {
      return res.error.statusCode === 401;
    }
  }
  return false;
};

const checkErrorStatus = (res) => {
  if ('error' in res) {
    const statusCode = res.error.statusCode;
    if (statusCode >= 400 && statusCode < 500) {
      console.error(`Client error: ${statusCode}`, res.error);
    } else if (statusCode >= 500) {
      console.error(`Server error: ${statusCode}`, res.error);
    }
  }
};

export const baseQuery = async (args, api, extraOptions) => {
  let result = await baseQueryLocal(args, api, extraOptions);
  if (checkAuthStatus(result)) {
    logout();
  }

  checkErrorStatus(result);
  return result;
};

export const Api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [],
  endpoints: () => ({}),
});
