import { useDialog } from '../../providers/dialog.provider';
import { Dialog } from '@mui/material';
import Button from '../button';
import Input from '../input';
import { Form, Field } from 'react-final-form';
import { TextArea } from '../input/TextArea';
import { createFAQ } from '../../store/actions/faq';

export const FaqModal = ({ onSuccess }) => {
  const dialog = useDialog();

  const handleSubmit = async (values) => {
    try {
      await createFAQ(values);
      dialog.close();
      onSuccess();
    } catch (error) {
      alert(`Ошибка при создании FAQ: ${error}`);
    }
  };

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          maxWidth: '623px',
          width: '100%',
          margin: 0,
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-p-6 tw-bg-white">
        <h4 className="tw-leading-5 tw-mb-8">Создание вопроса – ответа</h4>
        <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-end tw-gap-6">
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true, pristine: true, values: true }}
          >
            {({ handleSubmit, form }) => {
              const { values, pristine } = form.getState();
              const isDisabled = pristine || !values.header || !values.content;

              return (
                <form onSubmit={handleSubmit} className="tw-w-full tw-flex tw-flex-col tw-h-full">
                  <div className="tw-w-full">
                    <div className="tw-mb-8">
                      <div className="tw-mb-3">Вопрос</div>
                      <Input
                        form={form}
                        name="header"
                        type="text"
                        required
                        size="small"
                      />
                    </div>
                    <div className="tw-mb-10">
                      <div className="tw-mb-3">Ответ</div>
                      <Field name="content">
                        {({ input }) => (
                          <TextArea
                            {...input}
                            required
                            multiline
                            rows={6}
                            fullWidth
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-end tw-mt-4">
                    <Button
                      variant="text"
                      className="tw-mr-[20px] tw-text-[#666666] tw-leading-[18px] tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent"
                      onClick={() => dialog.close()}
                    >
                      Отменить
                    </Button>
                    <Button
                      disabled={isDisabled}
                      className="tw-w-fit tw-rounded-lg tw-px-4 tw-py-3 tw-font-medium"
                      type="submit"
                    >
                      Создать
                    </Button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </Dialog>
  );
};
