// import { applyMiddleware, compose } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { configureStore } from '@reduxjs/toolkit';
import combinedReducer from './reducers';

export const history = createBrowserHistory();

// const enhancedCompose =
//   process.env.NODE_ENV === 'development' ? composeWithDevTools : compose;

const middleware = [routerMiddleware(history)];

export const store = configureStore({
  reducer: combinedReducer(history),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV === 'development',
})

export const getCurrentState = () => store.getState();
export const dispatch = (...args) => store.dispatch(...args);