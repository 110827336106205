import generator from 'generate-password';

export const projectNameValidator = (name) => {
  if (name && name.length < 5) {
    return 'Название должно содержать более 4 символов';
  }
  return false;
};

export const urlValidator = (value) => {
  const error = 'Хост не валиден';
  if (!value || typeof value !== 'string') {
    return error;
  }

  const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
  if (!validHostnameChars.test(value)) {
    return error;
  }

  if (value.endsWith('.')) {
    value = value.slice(0, value.length - 1);
  }

  if (value.length > 253) {
    return error;
  }

  const labels = value.split('.');

  const isValid = labels.every(function (label) {
    const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

    const validLabel =
      validLabelChars.test(label) && label.length < 64 && !label.startsWith('-') && !label.endsWith('-');

    return validLabel;
  });

  return isValid ? false : error;
};

export const checkIsAdmin = (user) => {
  if (!user) return false;
  return user.role === 'admin';
};

export const generatePassword = () => {
  return generator.generate({
    length: 12,
    lowercase: true,
    uppercase: true,
    numbers: true,
    symbols: true,
    exclude: '", (, ), ~, =, &, `, ^',
  });
};

export const getWordEnding1 = (word, amount) => {
  // 1 товар, 2 товара, 5 товаров
  const str = String(amount);
  const lastChar = str.slice(-1);
  if (lastChar === '1') {
    return word;
  } else if (['2', '3', '4'].includes(lastChar)) {
    return word + 'а';
  }
  return word + 'ов';
};

export const getWordEnding2 = (word, amount) => {
  // 1 секунда, 2 секунды, 5 секунд
  const str = String(amount);
  const num = Number(amount);
  const lastChar = str.slice(-1);
  if (lastChar === '1' && str !== '11') {
    return word + 'а';
  } else if (['2', '3', '4'].includes(lastChar) && (num < 12 || num >= 22)) {
    return word + 'ы';
  }
  return word;
};

export const toCustomShort = (num) => {
  return new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(num);
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const formatValue = (value) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 3,
    notation: 'compact',
  }).format(value);

const trauncateFractionAndFormat = (parts, digits) => {
  return parts
    .map(({ type, value }) => {
      if (type !== 'fraction' || !value || value.length < digits) {
        return value;
      }

      let retVal = '';
      for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
        if (value[idx] !== '0') {
          counter++;
        }
        retVal += value[idx];
      }
      return retVal;
    })
    .reduce((string, part) => string + part);
};
const formatter = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0,
  maximumFractionDigits: 20,
});

export const formatThousands = (value) => trauncateFractionAndFormat(formatter.formatToParts(value), 2);

export const rememberAdminToReturn = (user, email, pass) => {
  const isAdmin = checkIsAdmin(user);
  if (!isAdmin) return;
  const prevUser = sessionStorage.getItem('a_user');
  if (!prevUser) {
    const login = Buffer.from(email, 'utf-8').toString('base64');
    const password = Buffer.from(pass, 'utf-8').toString('base64');
    sessionStorage.setItem('a_user', `${login} ${password}`);
  }
};

export const getProjectStatus = (value) => {
  switch (value?.report_status) {
    case 'finished':
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#EEFCEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#49CA4E] tw-text-xs'
            }
          >
            Завершено
          </div>
        </div>
      );
    case 'initial':
    case 'processing':
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
            }
          >
            В процессе
          </div>
        </div>
      );
    case 'failed':
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFEAEA] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FF4343] tw-text-xs'
            }
          >
            Прервано
          </div>
        </div>
      );
    case 'stopped':
      return (
        <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFEAEA] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FF4343] tw-text-xs'
            }
          >
            Остановлено
          </div>
        </div>
      );
    case 'optimized':
      return (
        <div className="tw-flex tw-flex-row  tw-justify-center tw-items-center">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#FFF8EC] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#FEB945] tw-text-xs'
            }
          >
            Оптимизация
          </div>
        </div>
      );
    default:
      return (
        <div className="tw-flex tw-flex-row tw-justify-center ">
          <div
            className={
              'tw-rounded-[9px] tw-bg-[#EEEEEE] tw-py-[5px] tw-px-[10px] tw-max-w-fit tw-text-[#7F7F7F] tw-text-xs'
            }
          >
            Статус
          </div>
        </div>
      );
  }
};
