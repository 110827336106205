import { useDialog } from '../../providers/dialog.provider';
import { Dialog } from '@mui/material';
import Button from '../button';
import { Form } from 'react-final-form';
import { removeFAQ } from '../../store/actions/faq';

export const FaqRemoveModal = ({ header, faq_id, onSuccess }) => {
  const dialog = useDialog();

  const handleSubmit = async (values) => {
    try {
      await removeFAQ(faq_id);
      onSuccess();
      dialog.close();

    } catch (error) {
      alert(`Ошибка при удалении FAQ: ${error}`);
    }
  };

  return (
    <Dialog
      open={dialog.visibility}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '8px',
          maxWidth: '623px',
          width: '100%',
          margin: 0,
        },
      }}
    >
      <div className="tw-flex tw-flex-col tw-p-6 tw-bg-white">
        <div className="tw-flex tw-flex-row tw-w-full tw-items-center tw-justify-end tw-gap-6">
          <Form
            onSubmit={handleSubmit}
            subscription={{ submitting: true, pristine: true, values: true }}
          >
            {({ handleSubmit, form }) => {
              return (
                <form onSubmit={handleSubmit} className="tw-w-full tw-flex tw-flex-col tw-h-full">
                  <div className="tw-w-full">
                    <h1>Вы уверены, что хотите удалить вопрос: <b>{header}</b>?</h1>
                  </div>
                  <div className="tw-flex tw-justify-end tw-mt-4">
                    <Button
                      variant="text"
                      className="tw-mr-[20px] tw-text-[#666666] tw-leading-[18px] tw-w-fit tw-text-sm tw-p-0 hover:tw-bg-transparent"
                      onClick={() => dialog.close()}
                    >
                      Отменить
                    </Button>
                    <Button
                      className="tw-w-fit tw-rounded-lg tw-px-4 tw-py-3 tw-font-medium"
                      type="submit"
                    >
                      Удалить
                    </Button>
                  </div>
                </form>
              );
            }}
          </Form>
        </div>
      </div>
    </Dialog>
  );
};
