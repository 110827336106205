// import { deleteOrganization, postOrganization } from '../../../store/actions/personalArea';
// import DeleteIcon from '../../../icons/delete';
import { useCallback, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../icons/deleteIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../icons/settingsIcon.svg';
import { ReactComponent as AddIcon } from '../../../icons/addIcon.svg';
import Button from '../../../components/button';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import { CrudTable, useCrudEntity } from '../../../components/table/CrudTable';
import { adminAPIs } from '../../../services/adminAPIs';
import { useEffect } from 'react';
import { Form } from 'react-final-form';
import { SearchInput } from '../../../components/input/search';
import { useDialog } from '../../../providers/dialog.provider';
import { AddOrganization } from './modals/AddOrganization';
import { Pagination } from '../../../components/table/pagination';
import { usePagination } from '../../../components/table/usePagination';
import { EditOrganization } from './modals/EditOrganization';
import { ConfirmModal } from '../../../components/modal/ConfirmModal';
import { UploadOrganizationFiles } from './modals/UploadOrganizationFiles';
import { CardOrganizationModal } from './modals/CardOrganizationModal';

export default function Organizations() {
  const dialog = useDialog();
  const [data, setData] = useState([]);

  const handleCardOrganization = useCallback(
    (value) => {
      dialog.open(<CardOrganizationModal organizations={value} />);
    },
    [dialog]
  );

  const columns = {
    name: {
      label: 'Название',
      renderBodyCell: (value) => <span className="tw-text-[#0844B4] tw-cursor-pointer" onClick={() => handleCardOrganization(value)}>{value?.name}</span>
    },
    inn: {
      label: 'ИНН',
      renderBodyCell: (value) => <>{value?.inn === 'None' ? <p>Не указан</p> : <p>{value?.inn}</p>}</>,
    },
    sign: {
      label: 'Признак',
      renderBodyCell: (value) => <>{value?.type.length === 0 ? <p>Не указан</p> : <p>{value?.type[0]}</p>}</>,
    },
    limit: {
      label: 'Лимит (месяц)',
      renderBodyCell: (value) => (
        <>
          <p className="">
            {value?.count_scan_in_month} / {value?.scan_limit_max}
          </p>
        </>
      ),
    },
  };
  const crudButtons = (entity) => {
    return (
      <div className="tw-flex tw-flex-row tw-gap-6 tw-items-center tw-justify-end">
        <Button
          variant="text"
          className="tw-w-fit tw-p-0 hover:tw-bg-inherit"
          onClick={() => showEditOrganizationModal(entity)}
        >
          <SettingsIcon />
        </Button>
        <Button
          variant="text"
          className="tw-w-fit tw-p-0 hover:tw-bg-inherit"
          onClick={() => showConfirmationModal(entity)}
        >
          <DeleteIcon />
        </Button>
      </div>
    );
  };

  const organizationsCrudAdapter = useCrudEntity({
    readAll: (limit = 10, page_num = 1, query = '') => adminAPIs.getOrganization(limit, page_num, query),
    create: (body) =>
      adminAPIs
        .addOrganization(body)
        .then(() => {
          createNotification('Организация создана');
          organizationsCrudAdapter.readAll().then((res) => setData(res.data));
          dialog.close();
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Произошла ошибка при создании организации');
        }),
    delete: (id) =>
      adminAPIs
        .deleteOrganization(id)
        .then(() => {
          organizationsCrudAdapter.readAll().then((res) => setData(res.data));
          dialog.close();
        })
        .catch((err) => {
          console.error(err);
          createErrorNotification('Ошибка удаления');
          dialog.close();
        }),
  });

  const fetchData = useCallback(
    async (limit = 10, page_num = 1, query = '') => {
      try {
        const res = await organizationsCrudAdapter.readAll(limit, page_num, query);
        return res;
      } catch (err) {
        console.error(err);
        createErrorNotification('Что-то пошло не так');
      }
    },
    [organizationsCrudAdapter]
  );

  const {
    data: paginatedData,
    currentPage,
    goToPage,
    total,
    setPageSize,
    pageSize,
    refetch,
    handleChangeQuery
  } = usePagination(fetchData);

  useEffect(() => {
    setData(paginatedData);
  }, [paginatedData]);

  const showAddOrganizationModal = useCallback(() => {
    dialog.open(<AddOrganization onSubmit={organizationsCrudAdapter.create} />);
  }, [dialog, organizationsCrudAdapter]);

  const showUpdateOrganizationsModal = useCallback(() => {
    dialog.open(<UploadOrganizationFiles />)
  }, [dialog])

  const showEditOrganizationModal = useCallback(
    (entity) => {
      dialog.open(<EditOrganization entity={entity} onUpdate={refetch} />);
    },
    [dialog, refetch]
  );

  const showConfirmationModal = useCallback(
    (entity) => {
      dialog.open(
        <ConfirmModal
          title={'Вы уверены, что хотите удалить организацию?'}
          onAgree={organizationsCrudAdapter.delete}
          value={entity}
        />
      );
    },
    [dialog, organizationsCrudAdapter]
  );
  const handleSearchChange = (value) => {
    handleChangeQuery(value.toString());
  };
  const handleLimitChange = (value) => {
    setPageSize(value);
    goToPage(1);
  };

  return (
    <>
      <div className="tw-flex tw-flex-col">
        <div className="tw-bg-white tw-py-[25px] tw-rounded-lg">
          <div className={`tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-4 tw-flex-wrap tw-gap-[20px] `}>
            <Form onSubmit={() => {}}>
              {() => (
                <>
                  <SearchInput
                    name="search"
                    className="tw-max-w-[312px]"
                    onSearchChange={handleSearchChange}
                    placeholder="Найти организацию"
                    type="text"
                    required
                  />
                </>
              )}
            </Form>
            <div className="tw-flex tw-flex-row tw-items-center tw-gap-4">
              <Button
                variant="text"
                className="tw-p-2 tw-w-fit tw-flex tw-flex-row tw-gap-1 tw-text-[#0844B4] tw-text-sm tw-leading-[18px] tw-font-semibold"
                onClick={() => showAddOrganizationModal()}
              >
                <AddIcon /> Новая организация
              </Button>
              <Button variant="text" className="tw-w-fit tw-p-2" onClick={() => showUpdateOrganizationsModal()}>
                <SettingsIcon />
              </Button>
            </div>
          </div>
          <CrudTable rows={data} entityColumns={columns} crudButtons={crudButtons} />
          <div>
            <Pagination
              rowsPerPage={pageSize}
              count={total}
              page={currentPage}
              onPageChange={goToPage}
              onLimitChange={handleLimitChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
