import { request } from '../../utils/request';

export const createFAQ = async (values) => {
  try {
    return await request(`/api/admin/faq`, {
      method: "POST",
      body: values,
    });
  } catch (error){
    throw new Error(error)
  }
}

export const removeFAQ = async (faq_id) => {
  try {
    return await request(`/api/admin/faq/${faq_id}`, {
      method: "DELETE",
    });
  } catch (error){
    throw new Error(error)
  }
}

export const editFAQ = async (values) => {
  try {
    const { id, header, content } = values;
    return await request(`/api/admin/faq/${id}`, {
      method: "PATCH",
      body: {
        content: content,
        header: header
      },
    });
  } catch (error) {
    throw new Error(error);
  }
};